import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home'

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}


Vue.use(VueRouter)

const routes = [
    {
        // 方案详情
        path: '/planDetail',
        name: 'planDetail',
        component: () =>
            import ('@/views/thirdPage/IntelligentReporting/planDetail'),
    },{
        path: '/',
        name: 'Home',
        redirect: '/index',
        component: () =>
            import ('@/views/Home'),
        children: [

            {
                path: '/index',
                name: 'FirstPage',
                component: () =>
                    import ('@/views/FirstPage')

            },
            {
                // 第二页，旭德简介页
                path: 'information',
                name: 'information',
                redirect: '/information/introduction',
                component: () =>
                    import ('@/views/information'),
                children: [{
                        path: '/information/introduction',
                        name: 'introduction',
                        component: () =>
                            import ('@/views/SecondPage/introduction'),
                    },
                    {
                        // 公司动态
                        path: '/information/CompanyStatic',
                        name: 'CompanyStatic',
                        component: () =>
                            import ('@/views/SecondPage/CompanyStatic'),
                    },
                    {
                        // 公司动态详情
                        path: '/information/CompanyStatic/detail',
                        name: 'companyDetail',
                        component: () =>
                            import ('@/views/SecondPage/CompanyStatic/companyDetail'),
                    },
                    {
                        // 网点分布
                        path: '/information/distribution',
                        name: 'distribution',
                        component: () =>
                            import ('@/views/SecondPage/distribution'),
                    },

                    {
                        // 宣传视频
                        path: '/information/video',
                        name: 'video',
                        component: () =>
                            import ('@/views/SecondPage/Video/index'),
                    },

                    {
                        // 宣传视频详情
                        path: '/information/video/videodetail',
                        name: 'video',
                        component: () =>
                            import ('@/views/SecondPage/Video/videodetail'),
                    },

                    {
                        // 联系我们
                        path: '/information/contactus',
                        name: 'contactus',
                        component: () =>
                            import ('@/views/SecondPage/contactus'),
                    },

                ]
            },
            {
                // 志愿服务
                // 第三页
                path: '/volunterservce',
                name: 'volunterservce',
                redirect: '/volunterservce/Comprehensive',
                component: () =>
                    import ('@/views/thirdPage'),
                children: [{
                        // 综合评测
                        path: '/volunterservce/Comprehensive',
                        name: 'Comprehensive',
                        component: () =>
                            import ('@/views/thirdPage/Comprehensive/index'),
                    },
                    {
                        path: '/volunterservce/Comprehensive/evaluation',
                        name: 'evaluation',
                        component: () =>
                            import ('@/views/thirdPage/Comprehensive/evaluation'),


                    },
                    {
                        path: '/volunterservce/Comprehensive/details',
                        name: 'details',
                        component: () =>
                            import ('@/views/thirdPage/Comprehensive/details'),


                    },
                    // 答题
                    {
                        path: '/volunterservce/Comprehensive/answer',
                        name: 'answer',
                        component: () =>
                            import ('@/views/thirdPage/Comprehensive/answer'),


                    },

                    {
                        // 智能填报
                        path: '/volunterservce/Intelligentreporting',
                        name: 'Intelligentreporting',
                        component: () =>
                            import ('@/views/thirdPage/Intelligentreporting'),
                    },
                    {
                        // 个性化方案
                        path: '/intelligentReporting/personalizedPlan',
                        name: 'personalizedPlan',
                        component: () =>
                            import ('@/views/thirdPage/IntelligentReporting/personalizedPlan'),
                    },
                    {
                        // 方案详情
                        path: '/intelligentReporting/planDetail',
                        name: 'personalizedPlan',
                        component: () =>
                            import ('@/views/thirdPage/IntelligentReporting/planDetail'),
                    },
                    {
                        // 手动填报
                        path: '/volunterservce/Intelligentreporting/manual',
                        name: 'manual',
                        component: () =>
                            import ('@/views/thirdPage/Intelligent/manual'),
                    },
                    {
                        // 查看结果
                        path: '/volunterservce/Intelligentreporting/autoresult',
                        name: 'autoresult',
                        component: () =>
                            import ('@/views/thirdPage/Intelligent/autoresult'),
                    },

                    {
                        // 一对一服务
                        path: '/volunterservce/oneServe',
                        name: 'oneServe',
                        component: () =>
                            import ('@/views/thirdPage/oneServe'),
                    },

                ]


            },
            {
                // 数据查询
                path: 'dataQuery',
                name: 'dataQuery',
                redirect: '/dataQuery/findschool',
                component: () =>
                    import ('@/views/dataQuery'),
                children: [{

                        path: '/dataQuery/findschool',
                        name: 'findschool',
                        component: () =>
                            import ('@/views/dataQuery/child/findschool'),

                    },
                    {
                        path: 'findschool/schooldetail',
                        name: 'schooldetail',
                        component: () =>
                            import ('@/views/dataQuery/child/schooldetail'),

                    },
                    {
                        path: 'findProfessional',
                        name: 'findProfessional',
                        component: () =>
                            import ('@/views/dataQuery/child/findProfessional'),

                    },
                    {
                        path: 'scoresQuery',
                        name: 'scoresQuery',
                        component: () =>
                            import ('@/views/dataQuery/child/scoresQuery'),

                    },
                    {
                        path: 'findProfessional/specialtyDetail',
                        name: 'specialtyDetail',
                        component: () =>
                            import ('@/views/dataQuery/child/specialtyDetail'),

                    },
                ]
            },
            {
                path: '/index/ExpertDetail',
                name: 'ExpertDetail',
                component: () =>
                    import ('@/views/ExpertDetail')
            },
            {
                path: '/dynamicsOfCollegeEntranceExamination',
                name: 'dynamicsOfCollegeEntranceExamination',
                component: () =>
                    import ('@/views/dynamicsOfCollegeEntranceExamination'),
                children: [{
                        path: 'detial',
                        name: 'dynamicsOfCollegeEntranceExaminationdetial',
                        component: () =>
                            import ('@/views/dynamicsOfCollegeEntranceExamination/detial.vue'),
                    },
                    {
                        path: 'articellist',
                        name: 'dynamicsOfCollegeEntranceExaminationarticellist',
                        component: () =>
                            import ('@/views/dynamicsOfCollegeEntranceExamination/articellist.vue'),
                    }
                ]
            },
            {
                path: '/lectureHall',
                name: 'lectureHall',
                redirect: '/lectureHall/lectureHalllecturelist',
                component: () =>
                    import ('@/views/lectureHall'),
                children: [{
                        path: 'lectureHalllecturelist/lecturedetial',
                        name: 'lecturedetial',
                        component: () =>
                            import ('@/views/lectureHall/lecturedetail.vue'),
                    },
                    {
                        path: 'lectureHalllecturelist',
                        name: 'lectureHalllecturelist',
                        component: () =>
                            import ('@/views/lectureHall/lecturelist.vue'),
                    },
                    {
                        path: 'lectureHallvideolist',
                        name: 'lectureHallvideolist',
                        component: () =>
                            import ('@/views/lectureHall/videolist.vue'),
                    },
                    {
                        path: 'lectureHallvideolist/lectureHallvideodetail',
                        name: 'lectureHallvideodetail',
                        component: () =>
                            import ('@/views/lectureHall/videodetail.vue'),
                    }
                ]
            },
            {
                path: '/personalCenter',
                name: 'personalCenter',
                component: () =>
                    import ('@/views/personalCenter/index.vue'),
                children: [{
                        name: 'PersonalInformation',
                        path: 'personalInformation',
                        component: () =>
                            import ('@/views/personalCenter/personalInformation.vue'),
                    },
                    {
                        name: 'myOrder',
                        path: 'myOrder',
                        component: () =>
                            import ('@/views/personalCenter/myOrder.vue'),
                    },
                    {
                        name: 'myvolunteer',
                        path: 'myvolunteer',
                        component: () =>
                            import ('@/views/personalCenter/myvolunteer.vue'),
                    },
                    {
                        name: 'lectureRegistration',
                        path: 'lectureRegistration',
                        component: () =>
                            import ('@/views/personalCenter/lectureRegistration.vue'),
                    },
                    {
                        name: 'editPersonalInformation',
                        path: 'editPersonalInformation',
                        component: () =>
                            import ('@/views/personalCenter/editPersonalInformation.vue'),
                    },
                ]
            }
        ]
    },
    {
        //隐私政策
        path: '/Privacypolicy',
        name: 'Privacypolicy',
        component: () =>
            import ('@/views/Home/Privacypolicy')
    }, {
        //用户协议
        path: '/Useragreement',
        name: 'Useragreement',
        component: () =>
            import ('@/views/Home/Useragreement')
    },
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router