import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import '@/assets/css/common.css'
import '@/assets/css/tcolor.less'
import lpyMixin from '@/lpyMixin'
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
    // import login from '@/views/login/index.js'
    // import login from './login.vue'


import LoginDialog from '@/views/login/index.vue'
Vue.use(ElementUI)
Vue.use(lpyMixin)

// 这里可以用Vue.extend()创建子类，但是我没打算注册为组件，就直接`new Vue`
export const loginDialog = new Vue(LoginDialog)
loginDialog.$mount(document.createElement('div'))
document.body.appendChild(loginDialog.$el)
Vue.prototype.$loginDialog = loginDialog.gologin




new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')