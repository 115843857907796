<template>
  <div>
    <!-- 登录二维码 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="350px"
      style="height: 600px"
    >
      <div style="height: 270px">
        <img class="img" :src="imgsrc" alt="" />
      </div>
      <div style="text-align: center">{{ scanHint }}</div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { getLoginQrcode, getQrcodeLoginInfo } from "@/api/login.js";

export default {
  data() {
    return {
      dialogVisible: false,
      imgsrc: "", //登录二维码
      scanHint: "请使用微信或OK志愿小程序扫一扫登录", //是否扫描
    };
  },
  methods: {
    scanlogin(code) {
      let interval = setInterval(() => {
        axios
          .get("/mobile/user/getQrcodeLoginInfo", {
            params: {
              code: code,
            },
          })
          .then((result) => {
            if (this.dialogVisible == false) {
              clearInterval(interval);
            }
            result = result.data;
            console.log(result);
            if (result.code == 1) {
              let data1 = result.data || {};
              if (data1.type == 1) {
                this.scanHint = "已扫码";
              } else if (data1.type == 2) {
                this.scanHint = "同意登录";
                if (data1.sessionId) {
                  clearInterval(interval);
                  this.dialogVisible = false;

                  this.islogin = true;
                  let sessionId = data1.sessionId || "";
                  window.sessionStorage.setItem("sessionId", sessionId);
                  let sysUserdata = data1.sysUser || {};
                  let sysUser = JSON.stringify(sysUserdata);
                  window.sessionStorage.setItem("sysUser", sysUser);
                  let studentdata = data1.student || {};
                  let student = JSON.stringify(studentdata);
                  window.sessionStorage.setItem("student", student);
                }
              }
            }
          });
      }, 500);
    },
    gologin() {
      this.dialogVisible = true;
      getLoginQrcode().then((res) => {
        if (res.code == 1) {
          console.log(res);
          let data = res.data || {};
          this.imgsrc = data.qrcode || "";
          this.scanlogin(data.code);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>