import axios from '@/util/request'

//GET /sys/sys-area/getCityList
// 查询城市
export const getCityList = (params) => {
    return axios({
        url: '/com/sys/sys-area/getCityList',
        method: 'get',
        params
    })
}

//GET /sys/sys-area/getCountyList
//查询区县
// GET /com/sys/sys-area/getCountyList
export const getCountyList = (params) => {
    return axios({
        url: '/com/sys/sys-area/getCountyList',
        method: 'get',
        params
    })
}

//GET /sys/sys-area/getProvinceList
//查询所有省份
export const getProvinceList = (params) => {
        return axios({
            url: '/com/sys/sys-area/getProvinceList',
            method: 'get',
            params
        })
    }
    // POST /com/had-login/lecture-info/getLectureInfoPage
    // 讲座信息管理查询列表
export const getLectureInfoPage = (data) => {
        return axios({
            url: '/com/had-login/lecture-info/getLectureInfoPage',
            method: 'POST',
            data
        })
    }
    // POST /mobile/expert-info/getExpertPage
    // 查看专家分页列表
export const getExpertPage = (data) => {
        return axios({
            url: '/mobile/expert-info/getExpertPage',
            method: 'POST',
            data
        })
    }
    // GET /mobile/content-info/getContentInfo
    // 内容-获取单个详情
export const getContentInfo = (params) => {
        return axios({
            url: '/mobile/content-info/getContentInfo',
            method: 'get',
            params
        })
    }
    // POST /mobile/content-info/pageAdvertisementInfo
    // 广告&宣传图-获取分页列表
export const pageAdvertisementInfo = (data) => {
        return axios({
            url: '/mobile/content-info/pageAdvertisementInfo',
            method: 'post',
            data
        })
    }
    // GET /mobile/content-info/getAdvertisementDetail
    // 广告&宣传图-获取单个详情
export const getAdvertisementDetail = (params) => {
        return axios({
            url: '/mobile/content-info/getAdvertisementDetail',
            method: 'GET',
            params
        })
    }
    //POST /mobile/content-info/pageVideoInfo
    // 获取分页列表   宣传视频
export const getVideo = (data) => {
        return axios({
            url: '/mobile/content-info/pageVideoInfo',
            method: 'POST',
            data
        })
    }
    // POST /mobile/content-info/pageArticleInfo
    // 文章-获取分页列表(传参subjectId)
export const pageArticleInfo = (data) => {
        return axios({
            url: '/mobile/content-info/pageArticleInfo',
            method: 'post',
            data
        })
    }
    // GET /mobile/content-info/getArticleDetail
    // 小程序-文章-获取单个详情
export const getArticleDetail = (params) => {
    return axios({
        url: '/mobile/content-info/pageArticleInfo',
        method: 'get',
        params
    })
}

// GET /mobile/content-info/getArticleDetail
// 小程序-文章-获取单个详情
export const getArticleDetail1 = (params) => {
    return axios({
        url: '/mobile/content-info/getArticleDetail',
        method: 'get',
        params
    })
}


// POST /mobile/content-info/getAreaSitePage
// 区域网点-获取分页列表
export const getAreaSitePage = (data) => {
    return axios({
        url: '/mobile/content-info/getAreaSitePage',
        method: 'post',
        data
    })
}


// GET /mobile/content-info/getAreaSiteProvinceCount
// 区域网点-各个省份的数量
export const getAreaSiteProvinceCount = (params) => {
    return axios({
        url: '/mobile/content-info/getAreaSiteProvinceCount',
        method: 'get',
        params
    })
}

// /com/had-login/mobile/user-info/getStudentDetail
// 查询个人资料
export const getStudentDetail = (params) => {
    return axios({
        url: '/com/had-login/mobile/user-info/getStudentDetail',
        method: 'get',
        params
    })
}

// /com/had-login/group/qq-group/getQQGroupList
// 查询个人资料
export const getQQGroupList = (params) => {
    return axios({
        url: '/com/had-login/group/qq-group/getQQGroupList',
        method: 'get',
        params
    })
}