import axios from 'axios';
import { loginDialog } from '@/main.js'
import {
    Message
} from 'element-ui'
// import store from "@/store";
import {
    showLoading,
    hideLoading
} from "./loading"
import router from "@/router"

var service = axios.create({
    baseURL: process.env.BASE_URL,
    timeout: 600000,
    headers: {
        'X-Custom-Header': 'foobar'
    }
});

// 添加请求拦截器(可以检查是否登录)
service.interceptors.request.use(
    config => {
        // let usertoken=sessionStorage.getItem('token')
        // if(usertoken){
        //   // console.log(token,"99999")
        //   config.headers['sessionId']=usertoken
        // }
        if (config.headers['hadLoading']) {
            // console.log('1111111')
        } else {
            if (!config.noLoading) {
                showLoading()
            }
        }
        // console.log(config)
        //  给每次请求加一个时间戳
        if (config.method == 'get') {
            // console.log(config)
            if (!config.params) {
                config.params = {}
            } else {
                config.params['_t'] = new Date().getTime()
            }
        }
        config.headers.sessionId = sessionStorage.getItem('sessionId') || ""
        return config;
    },
    error => {
        console.log('refuse');
        hideLoading();
        // 对请求错误做些什么
        return Promise.reject(error);
    });

// 添加响应拦截器
service.interceptors.response.use(
    response => {
        hideLoading();
        const status = response.status;
        const data = response.data;
        // console.log(response,'response')
        if (status !== 200) {
            return Promise.reject(response.statusText);
        } else if (data instanceof Blob) {
            return data
        } else if (response.config.url === "/com/file/fileDownLoad") {
            return response
        } else if (response.config.url === "/com/had-login/cdkey/cdkey-info/cdkeyExport") {
            return response
        } else if (data.code === -5) {
            Message({
                message: data.msg || data.message || "系统出错",
                type: 'error',
                duration: 1000,
            });
            return;
        } else if (data.code === -2 || data.code === -6 || data.code === -7) { //-1未登录，9009token失效
            localStorage.removeItem('token')
            Message.closeAll()
            Message({
                message: data.msg || data.message || "系统出错",
                type: 'error',
                duration: 1000,
                onClose: function() {
                    // router.push("/");
                    loginDialog.gologin()
                }
            });
            return;
        }else if (data.code === -1 ) { //-1未登录，9009token失效
            localStorage.removeItem('token')
            Message.closeAll()
            Message({
                message: "未登录，请先完成登录",//data.msg || data.message || "系统出错",
                type: 'error',
                duration: 1000,
                onClose: function() {
                    // router.push("/");
                    loginDialog.gologin()
                }
            });
            return;
        } else if (data.code === -3) {
            Message.closeAll()
                // setTimeout(() => {
            Message({
                message: "该账号已在其他地方登陆，已被迫下线",
                type: 'error',
                duration: 2000,
                onClose: function() {
                    router.push("/");
                }
            });
            // }, 2000);
        } else {
            if (data.code != 1) {
                Message.error({
                    message: data.msg
                })
                let err;
                throw err = new Error('服务器返回状态不为1');
            }
            return data;
        }
    },
    error => {
        // _loading.close()
        // console.log('err' + error) // for debug
        Message.closeAll()
            // console.log(1111)
        hideLoading()
        Message({
            message: "网络超时,请稍后重试",
            type: 'error'
        });
        return Promise.reject(error)
    }
);

export default service;
