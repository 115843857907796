import {
    getAdvertisementDetail, //顶部轮播图
} from "@/api";

var lpyMixin = {
    data() {
        return {
            smallImgError: "this.src='/default.jpeg'",
            QRcode: '',
            guanImg: ""
        }
    },
    mounted() {
        // this.getQRcode()
        // this.getguanImg()
    },
    methods: {
        // 小程序图片
        async getQRcode() {
            let res = await getAdvertisementDetail({ id: 7 });
            if (res.code == 1) {
                this.QRcode = res.data.images || '';
            }
        },
        // 官网图片
        async getguanImg() {
            let res = await getAdvertisementDetail({ id: 8 });
            if (res.code == 1) {
                this.guanImg = res.data.images || '';
            }
        },
    },

}


export default {
    install(Vue) {
        Vue.mixin(lpyMixin)


    }
}