import service from '@/util/request'
import axios from 'axios';
// 首页获取登录用的二维码
export const getLoginQrcode = (params) => {
    return service({
        url: '/mobile/user/getLoginQrcode',
        method: 'get',
        params
    })
}

// 首页循环调用查看是否扫码
// get /mobile/user/getQrcodeLoginInfo
// 参数 code
export const getQrcodeLoginInfo = (params) => {
    return service({
        url: '/mobile/user/getQrcodeLoginInfo',
        method: 'get',
        params
    })
}