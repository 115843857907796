<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import {
  getAdvertisementDetail, //顶部轮播图
} from "@/api";
export default {
  data() {
    return {};
  },
  mounted() {
    this.getQRcode();
    this.getImg();
  },
  methods: {
    async getQRcode() {
      let res = await getAdvertisementDetail({ id: 7 });
      if (res.code == 1) {
        // this.QRcode = res.data.images;
        this.$store.commit("setQRcode", res.data.images);
      }
    },
    async getImg() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        // this.QRcode = res.data.images;
        this.$store.commit("setImage", res.data.images);
      }
    },
  },
};
</script>
<style lang="less">
@import './assets/font/font.css';
#app {
  font-family: 'PingFangMedium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  overflow-y: hidden;
  height: 100%;
}

/deep/.el-button--primary {
  color: #fff;
  background-color: #1ab394 !important;
  border-color: #1ab394 !important;
}
</style>
