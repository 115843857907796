import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        QRcode: [], //小程序图片
        Image: [],
        QRcode: [],
        userId: sessionStorage.getItem('userId') || null,
        studentInfo: JSON.parse(sessionStorage.getItem('student')) || {},
        voPa: sessionStorage.getItem('voPa') || null,
        voNum: sessionStorage.getItem('voNum') || null,
        formObj: sessionStorage.getItem('formObj') || null //智能填报传参
    },
    mutations: {
        setQRcode(state, data) {
            state.QRcode = data
        },
        setImage(state, data) {
            state.Image = data
        },
        setVoPa(state, data) {
            sessionStorage.setItem('voPa', data);
            state.voPa = data;
        },
        setUserId(state, data) {
            sessionStorage.setItem('userId', data);
            state.userId = data;
        },
        setVoNum(state, data) {
            sessionStorage.setItem('voNum', data);
            state.voNum = data;
        },
        setFormObj(state, data) {
            sessionStorage.setItem('formObj', data);
            state.formObj = data;
        }
    },
    actions: {},
    modules: {}
})